import { createContext, useEffect, useState } from "react";
import DefaultLoginLogo from "./media/logo/ISTLogoWhite.png";
import DefaultLogo from "./media/logo/ISTLogo.png";
import DefaultDarkModeLogo from "./media/logo/ISTLogoWhite.png";
import System from "./models/system";

export const LogoContext = createContext();

export function LogoProvider({ children }) {
  const [logo, setLogo] = useState(DefaultLogo);
  const [darkModeLogo, setDarkModeLogo] = useState(DefaultDarkModeLogo);
  const [loginLogo] = useState(DefaultLoginLogo);
  const [isCustomLogo, setIsCustomLogo] = useState(false);
  const [isCustomDarkModeLogo, setIsCustomDarkModeLogo] = useState(false);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const { isCustomLogo, logoURL } = await System.fetchLogo();
        setLogo(logoURL || DefaultLogo);
        setIsCustomLogo(isCustomLogo || false);

        const { isCustomDarkModeLogo, darkModeLogoURL } =
          await System.isDefaultDarkModeLogo();
        setDarkModeLogo(darkModeLogoURL || DefaultDarkModeLogo);
        setIsCustomDarkModeLogo(isCustomDarkModeLogo || false);
      } catch (err) {
        setLogo(DefaultLogo);
        setDarkModeLogo(DefaultDarkModeLogo);
        setIsCustomLogo(false);
        setIsCustomDarkModeLogo(false);
        console.error("Failed to fetch logos:", err);
      }
    };

    fetchLogos();
  }, []);

  return (
    <LogoContext.Provider
      value={{
        logo,
        setLogo,
        darkModeLogo,
        setDarkModeLogo,
        loginLogo,
        isCustomLogo,
        isCustomDarkModeLogo,
      }}
    >
      {children}
    </LogoContext.Provider>
  );
}
